import { useTranslation } from "react-i18next";
import { getKhoaSanData, getKhoaNhiData } from "../constants/obstetrics-data";

export const useLableData = () => {
  const { t } = useTranslation();

  const ObstetricsData = getKhoaSanData(t);
  const ChildData = getKhoaNhiData(t);

  const labelsNK = [
    t("obstetricsData.radar_NK_1"),
    t("obstetricsData.radar_NK_2"),
    t("obstetricsData.radar_NK_3"),
    t("obstetricsData.radar_NK_4"),
    t("obstetricsData.radar_NK_5"),
    t("obstetricsData.radar_NK_6"),
  ];

  return {
    ObstetricsData,
    ChildData,
    labelsNK,
  };
};
