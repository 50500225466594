/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ChartExport from "./chart";
import {
  ExportContainer,
  ExportWrapper,
  NodataExport,
  Pagecurrent,
  SpaceHeight,
} from "./styled";
import TableExport from "./table";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SpinWrapper } from "../styled";
import { Spin } from "antd";
import { useMemo } from "react";
import { EDepartment } from "../../common/const";
import { storeSetCurrentDepartment } from "../../store/document-reducer";
import { getKhoaSanData, getKhoaNhiData } from "../../constants/obstetrics-data";

const ExportData = () => {
  const { t } = useTranslation();

  const ObstetricsData = getKhoaSanData(t);
  const ChildData = getKhoaNhiData(t);

  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state?.data?.dashboardData);
  const tableData = useSelector((state) => state?.data?.tableData);
  const currentDepartment = useSelector(
    (state) => state.document.currentDepartment
  );
  useEffect(() => {
    dispatch(storeSetCurrentDepartment(1));
  }, []);
  const timeLine = useMemo(() => {
    if (!dashboardData) {
      return null;
    }
    const response = dashboardData?.map((element) => {
      return element?.time;
    });
    return response || [];
  }, [dashboardData]);

  const checkFullNa = (arr) => {
    const find = arr?.find((findElement) => findElement !== null);
    if (!find && find !== 0) {
      return false;
    }
    return true;
  };

  const lineChartSK = useMemo(() => {
    if (!dashboardData) {
      return null;
    }
    const responseST = ObstetricsData?.map((element, index) => {
      return dashboardData?.map((dataElement) => {
        const point = dataElement?.data?.SK[index + 1]?.values?.ST;
        if (index === 6 || index === 7) {
          const perPoint = dataElement?.data?.SK[index + 1]?.values?.PER;
          return perPoint === "N/A" ? 0 : perPoint;
        }
        return point === "N/A" ? null : point;
      });
    });
    const responseSM = ObstetricsData?.map((element, index) => {
      return dashboardData?.map((dataElement) => {
        const point = dataElement?.data?.SK[index + 1]?.values?.SM;
        return point === "N/A" ? null : point;
      });
    });
    return (
      {
        SM: responseSM,
        ST: responseST,
      } || []
    );
  }, [ObstetricsData, dashboardData]);
  const lineChartNK = useMemo(() => {
    if (!dashboardData) {
      return null;
    }
    const responseST = ObstetricsData?.map((element, index) => {
      return dashboardData?.map((dataElement) => {
        const point = dataElement?.data?.NK[index + 1]?.values?.ST;
        return point === "N/A" ? null : point;
      });
    });
    const responseSM = ObstetricsData?.map((element, index) => {
      return dashboardData?.map((dataElement) => {
        const point = dataElement?.data?.NK[index + 1]?.values?.SM;
        return point === "N/A" ? null : point;
      });
    });
    return (
      {
        SM: responseSM,
        ST: responseST,
      } || []
    );
  }, [ObstetricsData, dashboardData]);

  const checkNotData = () => {
    const checked =
      ChildData?.filter(
        (element, index) => !!checkFullNa(lineChartNK?.ST[index])
      ) || [];
    if (checked?.length > 0) return true;
    return false;
  };

  const totalPageObstetricsData = useMemo(() => {
    let total = ObstetricsData?.length + 1 || 2;
    ObstetricsData.forEach((element, index) => {
      if (
        !checkFullNa(lineChartSK?.ST[index]) &&
        !checkFullNa(lineChartSK?.SM[index])
      ) {
        total -= 1;
      }
    });
    return total;
  }, [ObstetricsData]);
  const totalPageChildData = useMemo(() => {
    let total = ChildData?.length + 1 || 2;
    ObstetricsData.forEach((element, index) => {
      if (
        !checkFullNa(lineChartSK?.ST[index]) &&
        !checkFullNa(lineChartSK?.SM[index])
      ) {
        total -= 1;
      }
    });
    return total;
  }, [ChildData]);

  if (!tableData) {
    return (
      <SpinWrapper>
        <Spin size="large" />
      </SpinWrapper>
    );
  }

  return (
    <ExportContainer>
      <SpaceHeight />
      {(currentDepartment === EDepartment.OBSTETRIC ||
        currentDepartment === 3) && (
        <ExportWrapper id="exportDagta">
          {lineChartSK ? (
            <div>
              <div className="page html2pdf__page-break">
                <TableExport department="SK" />
                <Pagecurrent>
                  {" "}
                  {t("exportData.page")} 1/{totalPageObstetricsData}
                </Pagecurrent>
              </div>
              {ObstetricsData.map((element, index) => {
                if (
                  !checkFullNa(lineChartSK?.ST[index]) &&
                  !checkFullNa(lineChartSK?.SM[index])
                ) {
                  return <div />;
                }
                return (
                  <>
                    <div className="page html2pdf__page-break">
                      <ChartExport
                        criteria={element}
                        elementST={lineChartSK?.ST[index]}
                        elementSM={lineChartSK?.SM[index]}
                        evaluation={timeLine}
                        index={index}
                        department="SK"
                      />
                      <Pagecurrent>
                        {t("exportData.page")} {index + 2}/
                        {totalPageObstetricsData}
                      </Pagecurrent>
                    </div>
                  </>
                );
              })}
            </div>
          ) : (
            <SpinWrapper>
              <Spin size="large" />
            </SpinWrapper>
          )}
        </ExportWrapper>
      )}
      {checkNotData() &&
      (currentDepartment === EDepartment.PEDIATRIC ||
        currentDepartment === 3) ? (
        <ExportWrapper id="exportDagta2">
          {lineChartNK && (
            <div>
              <div className="page html2pdf__page-break">
                <TableExport department="NK" />
                <Pagecurrent>
                  {t("exportData.page")} 1/
                  {totalPageChildData}
                </Pagecurrent>
              </div>
              {ChildData.map((element, index) => {
                if (
                  !checkFullNa(lineChartNK?.ST[index]) &&
                  !checkFullNa(lineChartNK?.SM[index])
                ) {
                  return <div />;
                }

                return (
                  <div className="page html2pdf__page-break">
                    <ChartExport
                      criteria={element}
                      elementST={lineChartNK?.ST[index]}
                      elementSM={lineChartNK?.SM[index]}
                      evaluation={timeLine}
                      index={index}
                      department="NK"
                    />
                    <Pagecurrent>
                      {t("exportData.page")} {index + 2}/{totalPageChildData}
                    </Pagecurrent>
                  </div>
                );
              })}
            </div>
          )}
        </ExportWrapper>
      ) : (
        <NodataExport>
          {currentDepartment === EDepartment.PEDIATRIC &&
            t("export.nodataPediatric")}
        </NodataExport>
      )}
    </ExportContainer>
  );
};

export default ExportData;
